import Head from "next/head";
import React from "react";
// import {datadogRum} from "@datadog/browser-rum";
import "../styles/globals.scss";
import {withLDProvider} from "launchdarkly-react-client-sdk";
// import {UserProvider} from "@auth0/nextjs-auth0/client";
import SSRProvider from "react-bootstrap/SSRProvider";
import {Router} from "next/dist/client/router";
import {PaymentReservationProvider} from "../components/context/paymentReservation";
import {ParallaxProvider} from "react-scroll-parallax";
import {DEFAULT_LD_USER} from "../lib/constants";
import Analytics from "../components/analytics/Analytics";
import Zendesk, {
  ZendeskChatContextProvider,
} from "../components/Zendesk/Zendesk";
import {useFlags} from "launchdarkly-react-client-sdk";
import MaintenanceMode from "../components/MaintenanceMode/MaintenanceMode";
import App from "next/app";
import {getCollections} from "../lib/butter-api";
import {GlobalButterCMSContentContextProvider} from "../components/context/globalButterCMSContent";

Router.events.on("routeChangeComplete", (url) => {
  window?.analytics?.page(url);
});

function MyApp({Component, pageProps, globalContent = {}}) {
  const {maintenanceMode} = useFlags();

  // useEffect(() => {
  // const host = window.location.host;
  // ! Assumes the URL will be in the format of ENV.autononomy.com
  // ! Currently it's develop.autononomy or beta.autononomy
  // const env = host.includes("localhost") ? "local" : host.split(".")[0];
  // if (process.env.NODE_ENV !== "development") {
  // ! In development this will throw an error when pages are saved
  // ! that rum is already initialized but shouldn't in production when
  // ! moving pages.
  // datadogRum.init({
  //   applicationId: "94968623-a4d0-44ff-9695-3b20ec04aeb5",
  //   clientToken: "pub0f193d57083dd719ca56c246eb82ce5a",
  //   site: "datadoghq.com",
  //   service: "autonomy.com",
  //   // Specify a version number to identify the deployed version of your application in Datadog
  //   // version: '1.0.0',
  //   env,
  //   sampleRate: 100,
  //   trackInteractions: true,
  //   defaultPrivacyLevel: "mask-user-input",
  // });
  // datadogRum.startSessionReplayRecording();
  // }
  // }, []);

  const PricingOptionsProvider = ({children}) =>
    Component.usePricingOptions === false ? (
      <React.Fragment>{children}</React.Fragment>
    ) : (
      <PaymentReservationProvider vehicleName={Component.vehicleName}>
        {children}
      </PaymentReservationProvider>
    );

  return (
    <SSRProvider>
      {/* <UserProvider> */}
      {maintenanceMode ? (
        <MaintenanceMode />
      ) : (
        <GlobalButterCMSContentContextProvider value={globalContent}>
          <ZendeskChatContextProvider>
            <PricingOptionsProvider>
              <ParallaxProvider>
                <Head>
                  {Component.backgroundColor ? (
                    <meta
                      name="theme-color"
                      content={Component.backgroundColor}
                    />
                  ) : (
                    <meta name="theme-color" content="#fffffd" />
                  )}
                  <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1"
                  />
                </Head>
                <Analytics />
                <Component {...pageProps} />
                {!Component.hideZendeskChat && <Zendesk />}
              </ParallaxProvider>
            </PricingOptionsProvider>
          </ZendeskChatContextProvider>
        </GlobalButterCMSContentContextProvider>
      )}
      {/* </UserProvider> */}
    </SSRProvider>
  );
}

MyApp.getInitialProps = async (appContext) => {
  const appProps = await App.getInitialProps(appContext);

  let globalContent = {};
  if (appContext.ctx?.req) {
    const {global_header, "footer_-_vdp": global_footer} = await getCollections(
      ["global_header", "footer_-_vdp"]
    );

    globalContent = {
      global_header: global_header[0],
      global_footer: global_footer[0],
    };
  }

  return {globalContent, ...appProps};
};

const LDClientSideID = process.env.NEXT_PUBLIC_LAUNCHDARKLY_SDK_CLIENT_SIDE_ID;
const WrappedApp = LDClientSideID
  ? withLDProvider({
      clientSideID: process.env.NEXT_PUBLIC_LAUNCHDARKLY_SDK_CLIENT_SIDE_ID,
      context: {kind: "user", ...DEFAULT_LD_USER},
    })(MyApp)
  : MyApp;

export default WrappedApp;
