import {
  useState,
  useEffect,
  createContext,
  useContext,
  useCallback,
} from "react";
import {useRouter} from "next/router";
import Image from "next/legacy/image";
import _, {debounce} from "lodash";
import QuestionMark from "../../public/svg/question-mark.svg";
import QuestionMarkCircle from "../../public/svg/question-mark-circle.svg";

import classNames from "classnames";
import s from "./zendesk.module.scss";
import Script from "next/script";

const waitFor = (milliseconds) => {
  return new Promise((resolve) => {
    setTimeout(resolve, milliseconds);
  });
};

export const ZendeskChatContext = createContext({
  hideChat: () => {},
  isChatVisible: false,
  showChat: () => {},
});

export function ZendeskChatContextProvider({children}) {
  const [isChatVisible, setIsChatVisible] = useState(false);
  const [forceInitialize, setForceInitialize] = useState(false);
  const hideChat = useCallback(
    () => setIsChatVisible(false),
    [setIsChatVisible]
  );
  const showChat = useCallback(
    (openChatNow) => {
      setIsChatVisible(true);

      if (openChatNow) {
        window?.zE?.activate?.({hideOnClose: true});
      }
    },
    [setIsChatVisible]
  );

  return (
    <ZendeskChatContext.Provider
      value={{
        forceInitialize,
        hideChat,
        isChatVisible,
        setForceInitialize,
        showChat,
      }}
    >
      {children}
    </ZendeskChatContext.Provider>
  );
}

export default function Zendesk() {
  const router = useRouter();
  const {isChatVisible, showChat} = useContext(ZendeskChatContext);
  const [showChatCallout, setShowChatCallout] = useState(false);
  const [hideCallout, setHideCallout] = useState(false);
  const [hasAnimated, setHasAnimated] = useState(false);

  // Change this for offset top trigger in pixels
  const targetPixels = 200;

  // Add a scroll listener onLoad
  useEffect(() => {
    const isZendeskScriptLoaded = () =>
      typeof window !== "undefined" && window.zE?.activate;

    const handleScroll = async () => {
      if (hasAnimated) {
        return;
      }

      // Get the current Y scroll position;
      const position = window.pageYOffset;

      if (position > targetPixels) {
        if (!isZendeskScriptLoaded()) {
          return;
        }

        setHasAnimated(true);

        await waitFor(2000);
        showChat();
        await waitFor(2000);
        setShowChatCallout(true);
        await waitFor(4000);
        setShowChatCallout(false);
        await waitFor(2000);
        setHideCallout(true);
      }
    };

    let debouncedHandleScroll = debounce(handleScroll);
    window.addEventListener("scroll", debouncedHandleScroll);

    return () => {
      if (debouncedHandleScroll) {
        window.removeEventListener("scroll", debouncedHandleScroll);
      }
    };
  }, [hasAnimated]);

  const onZendeskClick = () => {
    // Activate Zendesk Chat Window
    window?.zE?.activate?.({hideOnClose: true});
  };

  return (
    <>
      {process.env.NEXT_ZENDESK_KEY && (
        <Script
          id="ze-snippet"
          src={`https://static.zdassets.com/ekr/snippet.js?key=${process.env.NEXT_ZENDESK_KEY}`}
        />
      )}
      <div
        className={classNames(
          s.chatCallout,
          {[s.show]: showChatCallout},
          {["d-none"]: hideCallout},
          {
            [s.zdBubbleNoCta]:
              router.pathname === "/privacy" || router.pathname === "/tos",
          }
        )}
      >
        Have a question?
        <div className={s.arrow}></div>
      </div>
      <button
        className={classNames(
          s.zdBtn,
          {[s.show]: isChatVisible},
          {[s.zdHome]: router.pathname === "/"},
          {
            [s.zdNoCta]:
              router.pathname === "/privacy" || router.pathname === "/tos",
          }
        )}
        onClick={() => onZendeskClick()}
      >
        <span className={classNames("d-lg-none", s.zdIcon)}>
          <Image
            src={QuestionMark}
            alt="Question mark"
            width={15}
            height={15}
            layout="fixed"
          />
        </span>
        <span className={classNames("d-none d-lg-inline-block", s.zdIcon)}>
          <Image
            src={QuestionMarkCircle}
            alt="Question mark"
            width={15}
            height={15}
            layout="fixed"
          />
        </span>
        <span className={s.zdLabel}>Help</span>
      </button>
    </>
  );
}
