import Image from "next/legacy/image";
import Logo from "../../public/images/logos/logo-no-mark.svg";
import styles from "./maintenanceMode.module.scss";

export default function MaintenanceMode() {
  return (
    <div className={styles.maintenance}>
      <div className={styles.logo}>
        <Image
          src={Logo}
          width={171}
          height={31}
          alt="Autonomy EV subscriptions"
        />
      </div>
      <div className={styles.content}>
        <p>Autonomy is down for maintenance.</p>
        <p>{`We'll be back soon!`}</p>
      </div>
    </div>
  );
}
